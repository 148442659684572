body {
  background-color: #ebecf0;
}
.main {
  margin-left: 40px;
  margin-right: 40px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px;
  width: 100%;
  background: #ebecf0;
  border-bottom: 1px solid #c1c7d0;
  margin-bottom: 24px;
}
.logo {
  width: 50px;
  height: 50px;
}
.page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
.page-title span {
  font-style: normal;
  font-weight: 500;
  font-size: 23.6783px;
  color: #172b4d;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 100%;
}
.app-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 24px;
  background: #f4f5f7;
  border-radius: 6px;
  width: -webkit-fill-available;
}
.app-section-body {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
}
.app-section-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
}
.app-section-title h1 {
  font-weight: 500;
  font-size: 35px;
  line-height: 40px;
  color: #253858;
  margin: 0;
}
.app-section-description {
  font-weight: 400;
  font-size: 14px;
  color: #172b4d;
}
.app-section-images {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  justify-content: center;
}

.app-section-image {
  border: 1px solid #dfe1e6;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
}
.app-section-image img {
  max-width: 100%;
}
.app-section-image span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #172b4d;
}

.app-section-image-1 span {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #172b4d;
}
.app-section-image-2 span {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #172b4d;
}
.top-menu {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.top-menu a {
  text-decoration: none;
}
.header-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  background: rgba(255, 255, 255, 0.0001);
  border-radius: 3px;
  gap: 4px;
}

.header-button span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.084px;
  color: #42526e;
}
.features-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}
.footer {
  margin-top: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
  gap: 28px;
  border-top: 1px solid #a5adba;
  flex: none;
  order: 3;
  flex-grow: 0;
}
.footer-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  gap: 16px;
}
.features-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #172b4d;
}

.li-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
}
ul {
  list-style-type: none;
}
.doc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}
